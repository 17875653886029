<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-select v-model="clave_producto" :items="claves_productos" label="Producto" item-text="nombre"
                        item-value="clave_producto" :error-messages="clave_productoErrors"
                        @input="$v.clave_producto.$touch()" @blur="$v.clave_producto.$touch()" color="secondary" />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="propietario" label="Propietario" :error-messages="propietarioErrors"
                        @input="$v.propietario.$touch()" @blur="$v.propietario.$touch()" color="secondary" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col class="d-flex align-center justify-space-around">
                     <v-switch v-model="estatus" true-value="S" false-value="N" label="Estatus" :disabled="!isEdit"
                        inset dense hide-details :append-icon="estatus == 'S' ? 'mdi-power' : 'mdi-power-off'"
                        color="secondary" />
                     <v-switch v-model="es_multi_usuario" true-value="S" false-value="N" label="Es Multi-Usuario" inset
                        dense hide-details :append-icon="es_multi_usuario == 'S' ? 'mdi-account-group' : 'mdi-account'"
                        color="secondary" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="usuarios" label="Usuarios" type="number" class="inputnum"
                        :error-messages="usuariosErrors" @input="$v.usuarios.$touch()" @blur="$v.usuarios.$touch()"
                        color="secondary" />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="hardware_id" label="Hardware ID" :error-messages="hardware_idErrors"
                        @input="$v.hardware_id.$touch()" @blur="$v.hardware_id.$touch()" color="secondary" />
                  </v-col>
               </v-row>
               <v-col cols="12" class="text-right"> <!-- Aquí se alinea a la derecha -->
                  <v-btn v-if="isEdit" :loading="isloading" color="red" @click="opencloseDeleteDialog">
                     <div class="text--primary">
                        HardWares
                     </div>
                  </v-btn>
                  <FormDelete v-if="showDeleteDialog" @opencloseDeleteDialog="opencloseDeleteDialog"
                     :showDeleteDialog="showDeleteDialog" />
               </v-col>
               <v-row>
                  <v-col class="d-flex align-center">
                     <v-dialog v-model="modal" width="300px">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field :value="caducidad" label="Caducidad" prepend-icon="mdi-calendar" readonly
                              v-bind="attrs" v-on="on" persistent-hint :hint="diff_months"
                              color="secondary"></v-text-field>
                        </template>
                        <v-date-picker v-model="caducidad" scrollable :min="current_date" full-width
                           @input="modal = false" />
                     </v-dialog>
                     <v-btn icon @click="add_reduce_months('-')"
                        class="mr-1 ml-1"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                     <v-btn icon @click="add_reduce_months('+')"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions class="d-flex justify-end pb-4">
            <v-btn @click="close" elevation="24" large>Cancelar</v-btn>
            <v-btn :loading="isloading" @click="save" elevation="24" large>Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import moment from "moment";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";
import FormDelete from './FormDelete';

export default {
   components: {
      FormDelete
   },
   props: {
      isEdit: Boolean,
   },
   validations: {
      clave_producto: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(50),
      },
      propietario: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(255),
      },
      hardware_id: {
         required,
         minLength: minLength(1),
         maxLength: maxLength(255),
      },
      caducidad: {
         required,
         valid: function () {
            return moment(this.caducidad).isAfter(moment());
         },
      },
      usuarios: {
         required,
         my_numeric: function () {
            this.usuarios = +this.usuarios;
            if (Number.isInteger(this.usuarios)) {
               if (this.usuarios >= 1) {
                  return true;
               }
            }
            return false;
         },
      },
   },
   data: function () {
      return {
         id: null,
         clave_producto: null,
         propietario: null,
         hardware_id: null,
         caducidad: null,
         estatus: null,
         es_multi_usuario: null,
         usuarios: null,

         modal: false,
         claves_productos: [],
         dialog: false,
         isloading: false,
         showDeleteDialog: false
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertLicencia", {
                     clave_producto: this.clave_producto,
                     propietario: this.propietario,
                     hardware_id: this.hardware_id,
                     caducidad: this.caducidad,
                     es_multi_usuario: this.es_multi_usuario,
                     usuarios: this.usuarios,
                  });
               } else {
                  res = await this.$store.dispatch("updateLicencia", {
                     id: this.id,
                     clave_producto: this.clave_producto,
                     propietario: this.propietario,
                     hardware_id: this.hardware_id,
                     caducidad: this.caducidad,
                     estatus: this.estatus,
                     es_multi_usuario: this.es_multi_usuario,
                     usuarios: this.usuarios,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "Validacion";
            }
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      opencloseDeleteDialog() {
         this.showDeleteDialog = !this.showDeleteDialog;
      },
      show: async function (i) {
         try {
            this.dialog = true;
            this.isloading = true;

            await this.$store.dispatch("getProductos");

            this.claves_productos = this.$store.state.products;

            this.id = i.id;
            this.clave_producto = i.clave_producto;
            this.propietario = i.propietario;
            this.hardware_id = i.hardware_id;
            this.caducidad = i.caducidad;
            this.estatus = i.estatus;
            this.es_multi_usuario = i.es_multi_usuario;
            this.usuarios = i.usuarios;
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
      add_reduce_months: function (type) {
         if (this.caducidad) {
            if (type == "+") {
               this.caducidad = moment(this.caducidad).add(1, "month").format("YYYY-MM-DD");
            } else if (type == "-") {
               this.caducidad = moment(this.caducidad).add(-1, "month").format("YYYY-MM-DD");
               if (moment(this.caducidad).isBefore(moment())) {
                  this.caducidad = moment().format("YYYY-MM-DD");
               }
            }
         }
      },
   },
   computed: {
      current_date() {
         return moment().toISOString();
      },
      diff_months() {
         if (this.caducidad) {
            let m = moment(this.caducidad).diff(moment(), "months");
            let d = moment(this.caducidad).diff(moment(), "days");
            return `Diferencia de ${m} meses, o ${d} dias`;
         }
      },
      title() {
         if (this.isEdit) {
            return "Editar Licencia";
         } else {
            return "Crear Licencia";
         }
      },
      clave_productoErrors() {
         const errors = [];
         if (!this.$v.clave_producto.$dirty) return errors;
         !this.$v.clave_producto.required && errors.push("Requerida");
         !this.$v.clave_producto.minLength && errors.push("Debe contener minimo 5 caracteres");
         !this.$v.clave_producto.maxLength && errors.push("Debe contener maximo 50 caracteres");
         return errors;
      },
      propietarioErrors() {
         const errors = [];
         if (!this.$v.propietario.$dirty) return errors;
         !this.$v.propietario.required && errors.push("Requerida");
         !this.$v.propietario.minLength && errors.push("Debe contener minimo 5 caracteres");
         !this.$v.propietario.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
      hardware_idErrors() {
         const errors = [];
         if (!this.$v.hardware_id.$dirty) return errors;
         !this.$v.hardware_id.required && errors.push("Requerida");
         !this.$v.hardware_id.minLength && errors.push("Debe contener minimo 1 caracteres");
         !this.$v.hardware_id.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
      caducidadErrors() {
         const errors = [];
         if (!this.$v.caducidad.$dirty) return errors;
         !this.$v.caducidad.required && errors.push("Requerida");
         !this.$v.caducidad.valid && errors.push("Debe ser una fecha valida y mayor a la de creacion");
         return errors;
      },
      usuariosErrors() {
         const errors = [];
         if (!this.$v.usuarios.$dirty) return errors;
         !this.$v.usuarios.required && errors.push("Requerida");
         !this.$v.usuarios.my_numeric && errors.push("Debe ser un entero positivo");
         return errors;
      },
   },
};
</script>

<style scoped>
.inputnum>>>input[type="number"] {
   appearance: textfield;
}

.inputnum>>>input::-webkit-outer-spin-button,
.inputnum>>>input::-webkit-inner-spin-button {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}
</style>
