<template>
  <v-dialog v-model="dialogVisible" max-width="480">
    <v-card title="Dialog 1">
      <v-card-title class="text-center display-1 my-5">Hardware</v-card-title>
      <v-card-text>
        <v-table dense>
          <thead>
            <tr>
              <th style="width: 300px;">Hardware</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in $store.state.hardwares" :key="item.hardware_id">
              <td class="text-center">{{ item.hardware_id }}</td>
              <td>
                <v-btn icon @click="deleteHardware(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions class="justify-end pb-4">
        <v-btn text @click="closeDialog" large elevation="24">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>




<script>

export default {
  props: {
    showDeleteDialog: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.obtenerDatos();
  },
  methods: {
    closeDialog() {
      this.$emit('opencloseDeleteDialog');
    },
    async obtenerDatos() {
      await this.$store.dispatch("getHardware");
    },
    async deleteHardware(item) {
      console.log('hola')
      await this.$store.dispatch("deleteHardwares", {
        item: item
      });
      await this.$store.dispatch("getHardware");
    },
  },
  data() {
    return {
      dialogVisible: this.showDeleteDialog,
    };
  },
};
</script>

<style scoped></style>
