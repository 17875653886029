<template>
   <div class="ma-xs-1 ma-md-4 mb-16">
     <Notification type="error" message="Error" ref="error_noti" />
     <Notification type="success" message="Información actualizada" ref="ok_noti" />
     <LicenciaForm @save="$refs.ok_noti.Show(), initialize()" :isEdit="false" ref="form" />
     <LicenciaForm @save="$refs.ok_noti.Show(), initialize()" :isEdit="true" ref="form_edit" />
     <v-data-table
       item-key="id"
       class="mb-16"
       :loading="isloading"
       :headers="headers"
       :items="filteredItems"
       :options.sync="options"
       :page.sync="page"
       :server-items-length="totalItems"
       :footer-props="{
         'items-per-page-options': [10, 30, 50, 100],
       }"
       :search="search"
     >
       <template v-slot:top>
         <v-toolbar flat>
           <v-btn color="secondary" class="custom-button" @click="newItem()">
             <span class="custom-text">
               <v-icon left>mdi-plus-circle</v-icon>
               Nueva
             </span>
           </v-btn>
           <v-btn @click="initialize" icon small class="ml-2">
             <v-icon small>mdi-autorenew</v-icon>
           </v-btn>
           <v-spacer></v-spacer>
           <v-select
             v-model="selectedClaveProducto"
             :items="claveProducto"
             label="Filtrar por:"
             outlined
             dense
             color="secondary"
             class="custom-width mt-5 mr-5"
             clearable
           ></v-select>
           <v-text-field
             class="d-none d-sm-flex"
             v-model="search"
             append-icon="mdi-magnify"
             label="Buscar"
             single-line
             hide-details
             color="secondary"
             dense
           ></v-text-field>
         </v-toolbar>
       </template>
 
       <template v-slot:[`item.estatus`]="{ item }">
         <v-icon x-small color="secondary" v-if="item.estatus == 'S'">mdi-circle</v-icon>
         <v-icon x-small v-else>mdi-circle-outline</v-icon>
       </template>
 
       <template v-slot:[`item.es_multi_usuario`]="{ item }">
         <v-icon x-small color="secondary" v-if="item.es_multi_usuario == 'S'">mdi-circle</v-icon>
         <v-icon x-small v-else>mdi-circle-outline</v-icon>
       </template>
 
       <template v-slot:[`item.actions`]="{ item }">
         <div class="d-flex align-center">
           <v-icon color="warning" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
           <v-icon color="blue" small v-if="item.estatus == 'S'" @click="download(item)">mdi-download-lock</v-icon>
         </div>
       </template>
     </v-data-table>
   </div>
 </template>
 
 <script>
 import moment from "moment";
 import LicenciaForm from "./LicenciaForm.vue";
 
 export default {
   components: {
     LicenciaForm,
   },
   data() {
     return {
       isloading: true,
       selectedClaveProducto: null,
       headers: [
         { width: "150px", text: "Clave Producto", value: "clave_producto" },
         { width: "150px", text: "Username", value: "username" },
         { width: "150px", text: "Propietario", value: "propietario" },
         { width: "130px", text: "Creación", value: "creacion" },
         { width: "130px", text: "Caducidad", value: "caducidad" },
         { width: "30px", text: "Acciones", value: "actions" },
       ],
       search: "",
       totalItems: 0,
       page: 1,
       options: {
         page: 1,
         itemsPerPage: 10,
         sortBy: ["id"],
         sortDesc: [true],
       },
     };
   },
   computed: {
     claveProducto() {
       return [...new Set(this.$store.state.licencias.items.map(licencia => licencia.clave_producto))];
     },
     filteredItems() {
       if (!this.selectedClaveProducto) {
         return this.$store.state.licencias.items;
       }
       return this.$store.state.licencias.items.filter(item => item.clave_producto === this.selectedClaveProducto);
     }
   },
   watch: {
     options: async function () {
       await this.initialize();
     },
     search: async function () {
       this.page = 1;
       await this.initialize();
     },
   },
   methods: {
     async initialize() {
       try {
         this.isloading = true;
         await this.$store.dispatch("getLicencias", {
           page: this.options.page,
           itemsPerPage: this.options.itemsPerPage,
           sortBy: this.options.sortBy[0] ?? "",
           sortDesc: this.options.sortDesc[0] ?? false,
           search: this.search,
         });
         this.totalItems = +this.$store.state.licencias.totalItems;
         localStorage.setItem("licencias_options", JSON.stringify(this.options));
       } catch (error) {
         this.$refs.error_noti.Show(error);
       } finally {
         this.isloading = false;
       }
     },
     async download(item) {
       try {
         this.isloading = true;
         let res = await this.$store.dispatch("getDownloadLicense", {
           licencia_id: item.licencia_id,
         });
         if (res.status == 200 && res.data) {
           const link = document.createElement("a");
           const file = new Blob([res.data], { type: "text/plain" });
           link.href = URL.createObjectURL(file);
           link.download = "licencia_ng.lic";
           link.click();
           URL.revokeObjectURL(link.href);
         }
       } catch (error) {
         this.$refs.error_noti.Show(error);
       } finally {
         this.isloading = false;
       }
     },
     async newItem() {
       this.$refs.form.show({
         id: null,
         clave_producto: null,
         propietario: null,
         hardware_id: null,
         caducidad: moment().format("YYYY-MM-DD"),
         estatus: "S",
         es_multi_usuario: "S",
         usuarios: 1,
       });
     },
     async editItem(item) {
       this.$store.state.selectedHardware = item;
       this.$refs.form_edit.show(item);
     },
   },
   created() {
     try {
       if (localStorage.getItem("licencias_options")) {
         this.options = JSON.parse(localStorage.getItem("licencias_options"));
       } else {
         this.options = {
           page: 1,
           itemsPerPage: 10,
           sortBy: ["id"],
           sortDesc: [true],
         };
         localStorage.setItem("licencias_options", JSON.stringify(this.options));
       }
     } catch (error) {
       this.options = {
         page: 1,
         itemsPerPage: 10,
         sortBy: ["id"],
         sortDesc: [true],
       };
       localStorage.setItem("licencias_options", JSON.stringify(this.options));
     }
   },
 };
 </script>
 
 <style scoped>
 .custom-button .custom-text {
   color: var(--v-primary-base);
   /* Usar la variable CSS de Vuetify para primary */
 }
 .custom-width {
   width: 20px;
 }
 </style>
 