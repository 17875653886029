<template>
   <div>
      <Dashboard />
   </div>
</template>

<script>
import Dashboard from "../components/Dashboard/Dashboard.vue";

export default {
   components: {
      Dashboard,
   },
};
</script>